export const trackSaleToggle = (copy, url) => {
    return {
        event: `list page nav - click`,
        category: { primaryCategory: `list page click`, subCategory: copy },
        attributes: { targetLink: copy, targetURL: url },
    };
};

export const trackRefine = (analytics = { page: {} }, modifier) => {
    const { page } = analytics;
    return {
        event: `list page - ${modifier} filter box`,
        eventEffect: 'flyout',
        category: { primaryCategory: page?.category?.pageType, subCategory: page?.category?.subCategory1 },
    };
};

export const trackFilter = (
    analytics = { page: {} },
    { identifier, label, value = '' },
    type = null,
    subType = null,
    useIdentifier = false,
    position = 'filter box',
) => {
    const { page } = analytics;

    return {
        event: 'list page nav - filter update',
        eventEffect: 'filter',
        category: { primaryCategory: page?.category?.pageType, subCategory: page?.category?.subCategory1 },
        attributes: {
            label: useIdentifier && identifier ? identifier : label,
            id: identifier || value,
            type: type,
            ...(subType && { subType }),
            ...(label === 'Price' && { valueMin: subType[0], valueMax: subType[1] }),
            position,
        },
    };
};

export const trackFavorites = (analytics = { page: {} }) => {
    const { page } = analytics;
    return {
        event: 'list page nav - your favourites',
        eventEffect: 'filter',
        category: { primaryCategory: page?.category?.pageType, subCategory: page?.category?.subCategory2 },
        attributes: { id: '', label: 'Designer', type: 'favourite designers' },
    };
};

export const trackFilterToggle = (analytics = { page: {} }, { identifier, label }, type = null, toggleStatus) => {
    const { page } = analytics;
    return {
        event: `list page nav - toggle update`,
        eventEffect: `toggle select all - ${toggleStatus}`,
        category: { primaryCategory: page?.category?.pageType, subCategory: page?.category?.subCategory1 },
        attributes: { label: label, id: identifier, type: type },
    };
};

export const trackPagination = (analytics = { page: {} }, value = '') => {
    const { category = {} } = analytics.page;
    return {
        event: `pagination - ${value}`,
        eventEffect: 'pagination',
        category: { primaryCategory: category.pageType, subCategory: category.subCategory1 },
        attributes: { value },
    };
};

export const trackLoadMore = (analytics = { page: {} }, value = '', currentPage = 1) => {
    const { category = {} } = analytics.page;
    return {
        event: `pagination - ${value}`,
        eventEffect: `pagination - ${value}`,
        category: { primaryCategory: category.pageType, subCategory: category.subCategory1 },
        attributes: { value, pageNumber: currentPage },
    };
};

export const trackBackToTop = (analytics = { page: {} }, currentPage = 1) => {
    const { category = {} } = analytics.page;
    return {
        event: 'back to top',
        eventEffect: 'back to top arrow clicked',
        category: { primaryCategory: 'listing page', subCategory: category.subCategory1 },
        attributes: { pageNumber: currentPage },
    };
};

export const trackDesignersSearchFocus = (analytics = { page: {} }) => {
    const { category = {} } = analytics.page;
    return {
        event: 'designers search filter - focus',
        eventEffect: 'filter input in focus',
        category: { primaryCategory: category?.pageType, subCategory: category.subCategory1 },
    };
};

export const trackDesignersSearchEntry = (analytics = { page: {} }) => {
    const { category = {} } = analytics.page;
    return {
        event: 'designers search filter - start',
        eventEffect: 'character typed',
        category: { primaryCategory: category?.pageType, subCategory: category.subCategory1 },
    };
};

export const trackAddToWishList = (info) => {
    return {
        event: 'add_to_wishlist',
        eventEffect: 'Add to wishlist',
        attributes: {
            partNumber: info.product?.partNumber || '',
            size: info?.sizeSku?.size?.centralSizeLabel || '',
            price: info?.product?.price?.finalPrice || '',
            designerName: info?.product?.designerName || '',
            colour: info?.product?.colour || '',
            category: info?.product?.masterCategory?.child?.label || '',
            category2: info?.product?.masterCategory?.child?.child?.label || '',
        },
    };
};

export const trackFiltersOnPLP = (filters) => {
    const getAppliedLabelsById = (id) => {
        const filter = filters.find((filter) => filter.id === id);
        return Array.isArray(filter?.appliedLabels) ? filter.appliedLabels.join(', ') : 'not set';
    };

    const CATEGORY_PREFIXES = ['INTL_2', 'INTL__', 'UKME__', 'APAC_'];
    const secondPositionId = filters?.[1]?.id ?? '';
    const hasSubCategory = CATEGORY_PREFIXES.some((prefix) => secondPositionId.startsWith(prefix));
    const sizeOptions = ['SIZE_SCHEME_CLOTHING SIZE', 'SIZE_SCHEME_MAN-APPAREL', 'SIZE_SCHEME_INTERNATIONAL', 'SIZE_SCHEME_MAN-SHIRTS', 'SIZE_SCHEME_CLOTHING'];

    const filterSize = sizeOptions.map(getAppliedLabelsById).find((value) => value !== 'not set') || 'not set';

    return {
        event: 'submit_filters',
        eventEffect: 'filter',
        category: { primaryCategory: 'listing page', subCategory: 'category list' },
        attributes: {
            filter_category: getAppliedLabelsById('CATEGORIES'),
            filter_subcategory: hasSubCategory ? filters?.[1]?.appliedLabels?.[0] : 'not set',
            filter_subcategory2: filters?.[1]?.appliedLabels?.[1] || 'not set',
            filter_colour: getAppliedLabelsById('Filter Colour'),
            filter_designer: getAppliedLabelsById('Brand'),
            filter_size: filterSize,
            filter_craftmanship: getAppliedLabelsById('Filter_Fabric'),
        },
    };
};
